import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./SideMenu.css";

const hideNode = (pathNode) => {
  // if pathNode has a frontMatter property with a toc_hide property set to true, then filter it out
  if (pathNode.frontMatter && pathNode.frontMatter.toc_hide) {
    return true;
  }
};

const SideMenu = ({ pathNode, pathname }) => {
  return (
    <div
      className="side-menu-container"
      style={{
        paddingBottom: 20,
      }}
    >
      <div className="side-menu-contents">
        {pathNode.children &&
          pathNode.children
            .filter((pn) => {
              return (
                !pn.path.includes("\\img") &&
                !pn.path.includes("/img") &&
                !pn.path.includes("faq") &&
                !pn.path.includes("FAQ") &&
                !hideNode(pn)
              );
            })
            .map((childNode) => {
              return <SideMenuHeader node={childNode} pathname={pathname} />;
            })}
      </div>
    </div>
  );
};

const SideMenuHeader = ({ node, pathname, margin = 0 }) => {
  const title =
    node.frontMatter.linkTitle || node.frontMatter.title || node.path;
  const [showChildren, setShowChildren] = useState(false);
  const hasChildren = node.children && node.children.length > 0;

  useEffect(() => {
    if (pathname.includes(node.path)) {
      setShowChildren(true);
    }
  }, [pathname, node.path]);

  return (
    <div className="side-menu-section">
      <div
        className="side-menu-section-header"
        onClick={() => setShowChildren(!showChildren)}
        style={{ cursor: "pointer" }}
      >
        <div className="side-menu-menu-section-header">
          <div className="side-menu-left-lockup">
            <div className="side-menu-header-text">{title} </div>
          </div>
        </div>
        <div className="side-menu-frame-2018775855">
          <svg
            className="side-menu-chevron"
            style={{
              transform: `rotate(${showChildren ? "-180deg" : "0deg"})`,
            }}
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.5 0L4 4" stroke="#D6D6D6" strokeLinecap="round" />
            <path d="M4 4L7.5 0" stroke="#D6D6D6" strokeLinecap="round" />
          </svg>
        </div>
      </div>
      {showChildren && hasChildren && (
        <SideMenuItem
          node={{
            frontMatter: {
              title: "Overview",
            },
            path: node.path,
          }}
          pathname={pathname}
          margin={margin + 10}
        />
      )}
      {showChildren &&
        hasChildren &&
        node.children
          .filter((np) => {
            return (
              !np.path.includes("\\img") &&
              !np.path.includes("/img") &&
              !np.path.includes("faq") &&
              !np.path.includes("FAQ") &&
              !hideNode(np)
            );
          })
          .map((childNode) => {
            return (
              <SideMenuItem node={childNode} pathname={pathname} margin={10} />
            );
          })}
    </div>
  );
};

const SideMenuItem = ({ node, pathname, margin = 0 }) => {
  const title =
    node.frontMatter && node.frontMatter.isGettingStarted
      ? "Getting Started"
      : node.frontMatter.linkTitle || node.frontMatter.title || node.path;

  const [isActiveNode, setIsActiveNode] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [showChildren, setShowChildren] = useState(false);
  const itemRef = useRef(null);

  useEffect(() => {
    if (!showChildren) {
      setShowChildren(pathname.includes(node.path));
    }
    setIsActiveNode(pathname.replace(/^\/|\/$/g, "") === node.path);
  }, [pathname]);

  useEffect(() => {
    if (isActiveNode && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [isActiveNode]);

  useEffect(() => {
    if (node.children && node.children.length > 0) {
      // are there any children that are not named img?
      const hasNonImgChildren =
        node.children.filter((np) => {
          return np.name !== "img";
        }).length > 0;

      setHasChildren(hasNonImgChildren);
    }
  }, [node]);

  const content = (
    <div
      className="side-menu-start-content"
      style={{
        marginLeft: margin,

        cursor: "pointer",
      }}
      onClick={() => setShowChildren(!showChildren)}
    >
      <svg
        className="side-menu-regular-icon"
        style={{
          transform: `rotate(${showChildren ? "90deg" : "0deg"})`,
          opacity: hasChildren ? 1 : 0,
        }}
        width="16"
        height="21"
        viewBox="0 0 16 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {showChildren ? (
          <path
            d="M8 17C11.3137 17 14 14.3137 14 11C14 7.68629 11.3137 5 8 5C4.68629 5 2 7.68629 2 11C2 14.3137 4.68629 17 8 17ZM7.35355 8.14645L9.85355 10.6464C10.0488 10.8417 10.0488 11.1583 9.85355 11.3536L7.35355 13.8536C7.15829 14.0488 6.84171 14.0488 6.64645 13.8536C6.45118 13.6583 6.45118 13.3417 6.64645 13.1464L8.79289 11L6.64645 8.85355C6.45118 8.65829 6.45118 8.34171 6.64645 8.14645C6.84171 7.95118 7.15829 7.95118 7.35355 8.14645Z"
            fill="white"
          />
        ) : (
          <path
            d="M8 16C10.7614 16 13 13.7614 13 11C13 8.23858 10.7614 6 8 6C5.23858 6 3 8.23858 3 11C3 13.7614 5.23858 16 8 16ZM14 11C14 14.3137 11.3137 17 8 17C4.68629 17 2 14.3137 2 11C2 7.68629 4.68629 5 8 5C11.3137 5 14 7.68629 14 11ZM7.35355 8.14645L9.85355 10.6464C10.0488 10.8417 10.0488 11.1583 9.85355 11.3536L7.35355 13.8536C7.15829 14.0488 6.84171 14.0488 6.64645 13.8536C6.45118 13.6583 6.45118 13.3417 6.64645 13.1464L8.79289 11L6.64645 8.85355C6.45118 8.65829 6.45118 8.34171 6.64645 8.14645C6.84171 7.95118 7.15829 7.95118 7.35355 8.14645Z"
            fill="#D6D6D6"
          />
        )}
      </svg>
      <div className="side-menu-content-frame ">
        <div className="side-menu-list-item-text">{title} </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        ref={isActiveNode ? itemRef : null}
        className={`side-menu-item ${
          isActiveNode && !hasChildren ? " side-menu-item-selected " : " "
        }`}
      >
        {!hasChildren ? (
          <Link to={node.path} className="focus-on-tab">
            {content}
          </Link>
        ) : (
          content
        )}
      </div>
      {showChildren && hasChildren && (
        <SideMenuItem
          node={{
            frontMatter: {
              title: "Overview",
            },
            path: node.path,
          }}
          pathname={pathname}
          margin={margin + 10}
        />
      )}
      {showChildren &&
        hasChildren &&
        node.children
          .filter((pn) => {
            return (
              !pn.path.includes("\\img") &&
              !pn.path.includes("/img") &&
              !pn.path.includes("faq") &&
              !pn.path.includes("FAQ") &&
              !hideNode(pn)
            );
          })
          .map((childNode) => {
            return (
              <SideMenuItem
                node={childNode}
                pathname={pathname}
                margin={margin + 10}
              />
            );
          })}
    </>
  );
};

export default SideMenu;
