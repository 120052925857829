import React from 'react';
import './Blockquote.css';

export const Blockquote = (props) => {
    const containsText = (element, searchText) => {
        if (typeof element === 'string' && element.includes(searchText)) {
            return true;
        }

        if (React.isValidElement(element) && element.props.children) {
            return React.Children.toArray(element.props.children).some(child => containsText(child, searchText));
        }

        return false;
    }

    const modifiedChildren = React.Children.map(props.children, child => {
        return child;
    });

    return modifiedChildren.map((child, index) => (
        <blockquote className={`custom-blockquote${containsText(child, 'Disclaimer:') ? '-disclaimer' : ''}`} key={index}>
            {child}
        </blockquote>
    ));
};
