import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useTransition } from "react";
import { Fade } from "@mui/material";

const ToolTip = ({ title, children, height, width, placement }) => {
  const AddToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      borderRadius: 4,
      maxWidth: width,
      height: height,
      display: "flex",
      justifyContent: "center",
      padding: "5px 12px 7px 12px",

      alignItems: "center",

      background: " var(--Neutral-Background-21-Rest, #292929)",
      boxShadow:
        "0px 4px 8px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
      fontSize: "var(--Size-200, 12px)",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "var(--Line-height-200, 16px)",
      color: "#fff",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#292929",
    },
  }));

  return (
    <AddToolTip title={title} placement={placement} arrow>
      {children}
    </AddToolTip>
  );
};
ToolTip.defaultProps = {
  placement: "bottom",
};

export default ToolTip;
