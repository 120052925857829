// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-page-container {
    color: #FFF;
    font-family: Segoe UI, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.49px;
    /* 153.06% */
    letter-spacing: 0.48px;
}

.markdown-page {
    border-radius: 10px;
    border: 1px solid #BBB;
    background: #242424;
    margin: 20px 80px 20px 80px;
    padding: 35px 62px 35px 62px ;
    min-height: 80%;
}

.github-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/MarkdownPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;IACvB,uBAAuB;AAC3B","sourcesContent":[".markdown-page-container {\n    color: #FFF;\n    font-family: Segoe UI, sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24.49px;\n    /* 153.06% */\n    letter-spacing: 0.48px;\n}\n\n.markdown-page {\n    border-radius: 10px;\n    border: 1px solid #BBB;\n    background: #242424;\n    margin: 20px 80px 20px 80px;\n    padding: 35px 62px 35px 62px ;\n    min-height: 80%;\n}\n\n.github-links {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    align-items: flex-start;\n    align-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
