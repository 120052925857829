import React from 'react';
import './Image.css';

// Override Image
export const Image = ({ alt, basePath, path, src, width }) => {
    let imagePath = '';
    if (!src.includes('http' || 'https')) {
        if (src.includes('./')) {
            src = src.replace('./', '');
            imagePath = `${basePath}/docs/${path}/${src}`;
        } else {
            imagePath = `${basePath}${src}`;
        }
    } else {
        imagePath = src;
    }

    const covertSrcToAltText = (src) => {
        let altText = src.substring(src.lastIndexOf('/') + 1, src.lastIndexOf('.'));
        altText = altText.replace(/_/g, ' ');
        altText = altText.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        return altText;
    };

    const altText = alt ? alt : covertSrcToAltText(src);

    const widthStyle = width ? { width: `${width}px` } : {};

    return (
        <div className='custom-image-container'>
            <img
                src={imagePath}
                className={`custom-image${width ? '-width' : ''}`}
                alt={altText}
                title={altText}
                style={widthStyle}
            />
        </div>
    )
};

